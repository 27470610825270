.discovery-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
}

.discovery-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  color: white;
  background-color: black;
}

.discovery-card h3 {
  margin: 0.5em auto;
  text-align: center;
}

.discovery-card p {
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 960px) {
  .discovery-card {
    width: 80%;
    margin-bottom: 2em;
  }
}
