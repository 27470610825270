@import url('https://use.typekit.net/dhk1xfh.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Quasimoda', 'Montserrat', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Typography */

h1 {
  font-size: 3.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

h3 {
  font-size: 1.6rem;
  font-weight: 700;
}

p {
  font-size: 1.2rem;
}

ul {
  font-size: 1.2rem;
}

.italic {
  font-style: italic;
}

.description {
  color: #8e8e8e;
}

/* Layout */

.container {
  margin: 0 auto;
  width: 95%;
  max-width: 1200px;
}
