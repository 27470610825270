.primary-button {
  background-color: black;
  color: white;
  padding: 1rem 2.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  border-radius: 50px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15rem;
}

.primary-button:hover {
  opacity: 80%;
}

.secondary-button {
  color: black;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  border-radius: 50px;
  border: solid black 2px;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.15rem;
  text-decoration: none !important;
}

.secondary-button:hover {
  opacity: 60%;
}
