.about-content {
  margin: 3em auto;
}

.about-headline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
}

.about-headline h1 {
  width: 600px;
  margin-right: 2em;
}

.about-headline img {
  width: 30%;
}

.about-mission {
  margin-top: 0;
}

.about-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.about-cta h2 {
  margin-right: 1em;
}

@media (max-width: 960px) {
  .about-content {
    width: 85%;
  }

  .about-headline {
    flex-direction: column;
  }

  .about-headline h1 {
    width: 100%;
    margin: 0 auto;
  }

  .about-headline img {
    width: 50%;
  }

  .about-cta {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
}

.about-profiles {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 960px) {
  .about-profiles {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .about-headline h1 {
    font-size: 2.5rem;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .about-headline img {
    width: 80%;
  }
}
