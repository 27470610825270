.footer {
  padding: 80px 0;
  background-color: black;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  height: 5em;
}

.footer p {
  margin: 0;
}

@media (max-width: 700px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-logo {
    margin-bottom: 1em;
  }
}
