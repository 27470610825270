.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 28%;
  min-width: 350px;
}

.hero-tagline {
  line-height: 4.2rem;
}

.hero-video {
  display: flex;
  justify-content: center;
  width: 60%;
  border-radius: 16px;
  overflow: hidden;
  padding-top: 45%;
  position: relative;
}

.hero-video video {
  position: absolute;
  width: 105%;
  top: 0;
  bottom: 0;
  object-fit: cover;
  margin: auto;
  transform: scale(1.3);
  /* margin: -0.1em; */
}

@media (max-width: 960px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-video {
    width: 80%;
    max-width: 600px;
  }

  .hero-content {
    width: 80%;
    max-width: 600px;
    align-items: center;
    margin-bottom: 3em;
  }

  .hero-tagline {
    font-size: 3rem;
    text-align: center;
  }
}
