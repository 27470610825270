.trusted-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 8em auto;
}

.trusted-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
}

.trusted-content h2 {
  margin: 0;
  line-height: 3.2rem;
}

.trusted-content span p {
  margin-top: 0;
  margin-bottom: 3em;
}

.image-carousel {
  display: flex;
  width: 55%;
  border: solid 2px #dedede;
  border-radius: 16px;
  background-color: #f6f6f6;
  box-shadow: -50px 50px 0 rgba(0, 0, 0, 0.025);
}

.image-carousel img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.swiper-pagination-bullets {
  bottom: 40px;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}

.swiper-button-prev {
  color: rgb(185, 185, 185) !important;
  opacity: 40%;
}

.swiper-button-next {
  color: rgb(185, 185, 185) !important;
  opacity: 40%;
}

@media (max-width: 960px) {
  .trusted-section {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 4em;
  }

  .image-carousel {
    width: 80%;
    max-width: 600px;
    margin-bottom: 5em;
  }

  .trusted-content {
    width: 80%;
    max-width: 550px;
    text-align: center;
  }
}
