.unity-app {
  display: flex;
  justify-content: center;
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f1f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unity-player {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 3;
  box-sizing: border-box;
  margin: 1em 3em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.unity-player button {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #969696;
  font-size: 3rem;
  border: none;
  opacity: 95%;
  background-color: #f1f2f7;
}

.unity-player button:hover {
  opacity: 80%;
}

.unity {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media (max-width: 960px) {
  .unity-player {
    margin: 1em 0;
  }
}
