.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  margin-top: 1.5em;
}

.header-logo {
  height: 100%;
}

@media (max-width: 700px) {
  .header {
    flex-direction: column;
    height: auto;
  }

  .header-logo {
    width: 80%;
    min-width: 300px;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a {
  font-size: 1.3rem;
  text-decoration: none;
  color: inherit;
  margin-left: 2em;
}

nav a:hover {
  opacity: 50%;
}

@media (max-width: 700px) {
  nav {
    /* flex-direction: column; */
    margin-top: 1em;
  }

  nav a {
    margin: 0 1em;
    font-size: 1.2rem;
  }
}
