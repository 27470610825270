.profile-card {
  display: inline-block;
  width: 43%;
  border-radius: 16px;
  padding: 2em;
  background-color: #f9f9f9;
}

.profile-pic {
  float: left;
  width: 40%;
  height: 85%;
  margin-right: 1.5em;
  object-fit: cover;
  object-position: top;
}

.placeholder {
  background-color: lightgrey;
}

.profile-card h3 {
  display: flex;
  margin: auto;
}

.profile-card ul {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.profile-card li {
  /* list-style-type: none; */
  list-style-position: inside;
}

@media (max-width: 960px) {
  .profile-card {
    width: auto;
    margin-bottom: 1.5em;
  }

  .profile-pic {
    max-width: 200px;
    max-height: 230px;
  }
}

@media (max-width: 600px) {
  .profile-card {
    padding: 1.3em;
  }
  .profile-card h3 {
    font-size: 1.1rem;
  }

  .profile-card li {
    font-size: 0.9rem;
  }
}
