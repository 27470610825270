.discovery-section {
  margin: 2em auto;
  padding: 8em 0;
  background-color: #f9f9f9;
}

.discovery-cards {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 960px) {
  .discovery-section {
    padding: 5em 0;
  }

  .discovery-cards {
    flex-direction: column;
    align-items: center;
  }
}
